define(['app', '$window', 'accessibilityFocusHelper', 'siteObj'],
    (app, $window, accessibilityFocusHelper, siteObj) => {
  const provenanceVerifiedIcon = () => {

    const component = {};

    component.config = {
      attribs: {
        productTitle: 'data-product-title'
      },
      selectors: {
        westendHeader: '.westendHeader',
        defaultContentId: '#product-description-content-22',
        fullScreenContentId: '#product-description-content-lg-22',
        provenanceUnderTitle: '#athenaProductPage_provenanceBundle',
        provenanceUnderWishList: '#athenaProductPage_provenanceBundleUnderWishList',
      },
      scrollTargetIds: {
        defaultHeadingId: '#product-description-heading-22',
        fullScreenHeadingId: '#product-description-heading-lg-22',
      }
    };

    component.init = (element) => {
      component.element = element;
      component.provenanceDefaultTab = document.querySelector(component.config.scrollTargetIds.defaultHeadingId);
      component.provenanceFullScreenTab = document.querySelector(component.config.scrollTargetIds.fullScreenHeadingId);
      component.provenanceDefaultContentWrapper = document.querySelector(component.config.selectors.defaultContentId);
      component.provenanceFullScreenContentWrapper = document.querySelector(component.config.selectors.fullScreenContentId);
      component.bundleUrl = siteObj.config.provenanceProductBundleUrl;
      component.sdkUrl = siteObj.config.provenanceSdkUrl;
      component.sdkHash = siteObj.config.provenanceSdkIntegrityHash;
      component.productTitle = component.element.getAttribute(component.config.attribs.productTitle);
      component.provenaceProductUnderTitle = document.querySelector(component.config.selectors.provenanceUnderTitle);
      component.provenanceUnderWishList = document.querySelector(component.config.selectors.provenanceUnderWishList);
      component.bind();
      component.initialiseBundle();

      return component;
    };

    component.bind = () => {
      component.element.addEventListener('click', component.handleVerifiedIconClick);
    };

    component.initialiseBundle = () => {
      const bundleTag = document.createElement('provenance-bundle');
      const bundleContainer = component.getBundleContainer();
      const script = document.createElement('script');

      bundleTag.setAttribute('url', component.bundleUrl);
      bundleTag.setAttribute('schema', 'Product');
      bundleTag.setAttribute('class', 'provenance-bundle');
      bundleTag.setAttribute('id', 'provenance-trust-badge');

      if (component.provenaceProductUnderTitle) {
        component.provenaceProductUnderTitle.insertAdjacentElement('beforeend', bundleTag);
      } else if (component.provenanceUnderWishList) {
        component.provenanceUnderWishList.insertAdjacentElement('beforeend', bundleTag);
      } else {
        bundleContainer && bundleContainer.insertAdjacentElement('beforeend', bundleTag);
      }

      script.type = 'text/javascript';
      script.src = component.sdkUrl;
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.setAttribute('integrity', component.sdkHash);
      script.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(script);
    }

    component.getBundleContainer = () => {
      return $window.innerWidth < 900
          ? component.provenanceDefaultContentWrapper
          : component.provenanceFullScreenContentWrapper;
    }

    component.handleVerifiedIconClick = () => {
      const westendHeader = document.querySelector(component.config.selectors.westendHeader);
      const headerHeight = westendHeader ? westendHeader.clientHeight : 0;
      const target = $window.innerWidth < 900 ? component.provenanceDefaultTab : component.provenanceFullScreenTab;

      if (target.getAttribute('aria-expanded') == 'false') {
        target.click();
      }

      component.scroll(target, headerHeight);
      component.trackVerifiedIconClick();
      accessibilityFocusHelper.focus(target);
    };

    component.scroll = (scrollTarget, headerHeight) => {
      app.element.scrollTo(scrollTarget.offsetTop - headerHeight, 500);
    }

    component.trackVerifiedIconClick = () => {
      $window.dataLayer.push({
        event: "provenance",
        eventAction: "Verified Product",
        eventCategory: "Provenance",
        eventLabel: component.productTitle ? component.productTitle : '',
      });
    }

    return component;
  };

  return provenanceVerifiedIcon;
});
